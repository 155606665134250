main {
  margin-top: 1rem;
  height: 80vh;
}
button, select {
  border-radius: 0 !important;
}
.btn {
  border-radius: 0;
}

span.bid_time {
  font-size: 0.8rem;
  color: #777;
}

.show {
  display: block;
}
.hide {
  display: none;
}

.video_show {
  display: block;
}
.video_hide {
  display: hidden;
}

.bidtable .online {
  color: #f56c62;
}
.bidtable .confirm {
  background-color: #A8A78833;
}
.bidtable .winbid {
  background-color: #FFD8D8;
}

.bidtable {
  /* table-layout: fixed; */
  /* display: block; */
  /* border-bottom: 1px solid #dee2e6; */
}
/* .bidtable tbody {
  display: block;
  max-height: 294px;
  overflow-y: auto;
  width: 100%;
}
.bidtable thead tr, .bidtable tbody tr {
  display: block;
}

.bidtable tr .time {
  width: 140px;
}
.bidtable tr .biduser {
  width: 380px;
}
.bidtable tr .paddle {
  width: 100px;
}
.bidtable tr .price {
  width: 207px;
}
.bidtable thead tr th {
  text-align: center;
}
.bidtable th, .bidtable td {
  border: 0;
}
.bidtable tbody tr .center {
  text-align: center;
} */

.tableFixHead {
  overflow: auto; height: 328px;
  padding: 0;
}
.tableFixHead thead tr {
  border-width: 0;
}
.tableFixHead thead th {
  position: sticky; top: 0; 
  /* z-index: 1; */
  /* height: 44px; */
  /* boarder-top: 1px solid #dee2e6; */
}
.tableFixHead table  { border-collapse: collapse; width: 100%; }
/*.live-bid .tableFixHead th, td { padding: 8px 16px; }*/
.tableFixHead table tr th { 
  background:#A8A788; 
}
.tableFixHead th, .tableFixHead td {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #dee2e6;
}
/* footer {
  maring:0;
  padding: 0;
  font-size: 9px;
  width: 100%;
} */
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.modal-content .modal-body p.item {
  padding-left: 1em;
  margin-bottom: 0;
}

.modal-content .modal-body .point {
  color:#f56c62;
}